import { Validators } from "@angular/forms";
import { FormConfigInterface } from "./interfaces/form-config.interface";
import { emailPattern, fiveDigitsPattern } from "../modules/shared/regex-pattern";

export const FI_CONFIG: FormConfigInterface = {
  completeProfile: [
    { Name: "given_name", Required: () => true, Type: "text", Order: 1, Readonly: true, Hide: () => false },
    { Name: "family_name", Required: () => true, Type: "text", Order: 2, Readonly: true, Hide: () => false },
    {
      Name: "contact_email",
      Type: "text",
      Order: 3,
      Readonly: false,
      Hide: () => false,
      Required: () => true,
      CustomValidators: [Validators.pattern(emailPattern)],
    },
    { Name: "phone", Type: "phone", Order: 4, Hide: () => false, Required: () => true },
    { Name: "locale", Type: "locale-picker", Order: 5, Hide: () => false, Required: () => true },
    { Name: "street_address", Required: () => true, Type: "text", Order: 6, Hide: () => false },
    {
      Name: "zipcode",
      Required: () => true,
      Type: "text",
      Order: 7,
      Hide: () => false,
      CustomValidators: [Validators.pattern(fiveDigitsPattern)],
    },
    { Name: "city", Required: () => true, Type: "text", Order: 8, Hide: () => false },
    { Name: "country_residence", Required: () => true, Type: "country-picker", Order: 9, Hide: () => false },
    {
      Name: "ssn",
      Required: (roles: string[]) => true,
      Type: "text",
      Order: 10,
      Readonly: true,
      Hide: (roles: string[]) => !roles.includes("synlab_access_user"),
    },
    { Name: "date_of_birth", Required: () => true, Type: "date-picker", Order: 11, Readonly: true, Hide: () => false },
    { Name: "gender", Required: () => true, Type: "gender-picker", Order: 12, Readonly: true, Hide: () => false },
  ],
  adminCompleteProfile: [
    { Name: "given_name", Required: () => true, Type: "text", Order: 1, Readonly: true, Hide: () => false },
    { Name: "family_name", Required: () => true, Type: "text", Order: 2, Readonly: true, Hide: () => false },
    {
      Name: "email",
      Type: "text",
      Order: 3,
      Readonly: true,
      Hide: () => false,
      Required: () => true,
      CustomValidators: [Validators.pattern(emailPattern)],
    },
    { Name: "phone", Type: "phone", Order: 4, Hide: () => false, Required: () => true },
    { Name: "locale", Type: "locale-picker", Order: 5, Hide: () => false, Required: () => true },
    { Name: "street_address", Required: () => true, Type: "text", Order: 6, Hide: () => false },
    {
      Name: "zipcode",
      Required: () => true,
      Type: "text",
      Order: 7,
      Hide: () => false,
      CustomValidators: [Validators.pattern(fiveDigitsPattern)],
    },
    { Name: "city", Required: () => true, Type: "text", Order: 8, Hide: () => false },
    { Name: "country_residence", Required: () => true, Type: "country-picker", Order: 9, Hide: () => false },
    { Name: "date_of_birth", Required: () => true, Type: "date-picker", Order: 11, Readonly: true, Hide: () => false },
    { Name: "gender", Required: () => true, Type: "gender-picker", Order: 12, Readonly: true, Hide: () => false },
  ],
  personalInfo: [
    { Name: "given_name", Required: () => true, Type: "text", Order: 1, Readonly: true, Hide: () => false },
    { Name: "family_name", Required: () => true, Type: "text", Order: 2, Readonly: true, Hide: () => false },
    {
      Name: "country_residence",
      Required: () => true,
      Type: "country-picker",
      Order: 3,
      Hide: () => false,
      Readonly: true,
    },
    {
      Name: "ssn",
      Required: (roles: string[]) => true,
      Type: "text",
      Order: 4,
      Readonly: true,
      Hide: (roles: string[]) => !roles.includes("synlab_access_user"),
    },
    { Name: "date_of_birth", Required: () => true, Type: "date-picker", Order: 5, Hide: () => false, Readonly: true },
    { Name: "gender", Required: () => true, Type: "gender-picker", Order: 6, Readonly: true, Hide: () => false },
  ],
  contact: [
    { Name: "country_address", Required: () => false, Type: "country-picker", Order: 0, Hide: () => false },
    { Name: "city", Required: () => true, Type: "text", Order: 0, Hide: () => false },
    {
      Name: "zipcode",
      Required: () => true,
      Type: "text",
      Order: 0,
      Hide: () => false,
      CustomValidators: [Validators.pattern(fiveDigitsPattern)],
    },
    { Name: "street_address", Required: () => true, Type: "text", Order: 0, Hide: () => false },
    { Name: "phone", Required: () => true, Type: "phone", Order: 0, Hide: () => false },
    {
      Name: "contact_email",
      Required: () => true,
      Type: "text",
      Order: 0,
      Readonly: false,
      Hide: () => false,
      CustomValidators: [Validators.pattern(emailPattern)],
    },
  ],
  adminContact: [
    { Name: "country_address", Required: () => false, Type: "country-picker", Order: 0, Hide: () => false },
    { Name: "city", Required: () => true, Type: "text", Order: 0, Hide: () => false },
    {
      Name: "zipcode",
      Required: () => true,
      Type: "text",
      Order: 0,
      Hide: () => false,
      CustomValidators: [Validators.pattern(fiveDigitsPattern)],
    },
    { Name: "street_address", Required: () => true, Type: "text", Order: 0, Hide: () => false },
    { Name: "phone", Required: () => true, Type: "phone", Order: 0, Hide: () => false },
    {
      Name: "contact_email",
      Required: () => true,
      Type: "text",
      Order: 0,
      Readonly: false,
      Hide: () => false,
      CustomValidators: [Validators.pattern(emailPattern)],
    },
    {
      Name: "email",
      Required: () => true,
      Type: "text",
      Order: 0,
      Readonly: false,
      Hide: () => false,
      CustomValidators: [Validators.pattern(emailPattern)],
    },
  ],
};

export const dummyDOBValue: string = "0001-01-01";
